const Status = ({
      teams,
      resetAction,
      revealCardsAction,
      showRevealButton,
      showCards,
      getAverage,
      getRange,
      isAdmin,
}) => {
      if (showCards) {
            return (
                  <>
                        {teams.length > 1 ? (
                              teams.map((team) => {
                                    const teamAverage = getAverage(team)
                                    if (!isNaN(teamAverage)) {
                                          return (
                                                <div className="text-center">
                                                      Average {team}:{' '}
                                                      {getAverage(team)}
                                                </div>
                                          )
                                    }
                                    return <></>
                              })
                        ) : (
                              <div className="text-center">
                                    Average: {getAverage()}
                              </div>
                        )}
                        <div className="m-auto w-full justify-center flex mt-2">
                              <button
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline "
                                    type="button"
                                    onClick={resetAction}
                              >
                                    Reset
                              </button>
                        </div>
                  </>
            )
      }

      return (
            <div className="m-auto w-full justify-center flex">
                  {showRevealButton ? (
                        <button
                              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline "
                              type="button"
                              onClick={revealCardsAction}
                        >
                              Reveal Cards
                        </button>
                  ) : (
                        <div className="text-center font-semi-bold">
                              Pick Your Cards
                        </div>
                  )}
            </div>
      )
}

export default Status
