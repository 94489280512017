import React from 'react'

import Img from '../banner/image/Cards1.png'

const Banner = ({ title, description, background }) => {
    return (
        <div className="Banner flex">
            <div class="Banner-content">
                <div className="Banner-title">{title}</div>
                <div className="Banner-description">{description}</div>
                <a href="https://marketplace.atlassian.com/apps/1232571/scrum-poker-estimates-for-jira?tab=overview&hosting=cloud">
                <button
                style={{
                    justifyContent: 'center',
                    color: 'white',
                    width: 200,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    display: 'block',
                    marginTop: 20
                }}
                className="flex text-white bg-green-500 hover:bg-green-300 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mt-2"
            >Get the Jira App Now!</button>
            </a>
            </div>
            <div className="Banner-image drop-shadow-lg">
                <img src={Img} alt="Scrum Poker made Easy" />
            </div>
        </div>
    )
}

export default Banner
