import { configureStore } from '@reduxjs/toolkit'
import chatReducer from '../reducer/reducer'

export default configureStore({
    reducer: chatReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: {
                // Ignore state paths, e.g. state for 'items':
                ignoredPaths: ['loggedInUser'],
            },
        }),
})
