import React, { useState } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Header from '../page/Header'
import { useSelector } from 'react-redux'

const ThemeWrapper = ({ children }) => {
    const [isDarkMode, setIsDarkMode] = useState(false)
    const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false)
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)

    const loggedInUser = useSelector((state) => state.loggedInUser)

    return (
        <>
            <div
                className={`${isDarkMode ? 'darkMode' : ''} ${
                    (isSignUpModalOpen || isLoginModalOpen) &&
                    'Modal-background'
                } transition-colors duration-500`}
                style={{ height: '100vh', position: 'relative', zIndex: 1 }}
                onClick={() => {
                    if (isSignUpModalOpen || isLoginModalOpen) {
                        setIsSignUpModalOpen(false)
                        setIsLoginModalOpen(false)
                    }
                }}
            >
                <Header
                    loggedInUser={loggedInUser}
                    setIsSignUpModalOpen={setIsSignUpModalOpen}
                    setIsLoginModalOpen={setIsLoginModalOpen}
                    setIsDarkMode={setIsDarkMode}
                    isDarkMode={isDarkMode}
                />
                <ToastContainer />
                {children}
            </div>
        </>
    )
}

export default ThemeWrapper
