import React, { useState, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'

const AdminDash = () => {
    const [boardOptions, setBoardOptions] = useState([])
    const [backlogOptions, setBacklogOptions] = useState([])
    const [boardValue, setBoardValue] = useState('')
    const [backlogValue, setBacklogValue] = useState('')
    // const [estimate, setEstimate] = useState('customfield_10016')
    const [statusOptions, setStatusOptions] = useState([])
    const [statusValue, setStatusValue] = useState('')
    const [estimateValue, setEstimateValue] = useState('')

    useEffect(() => {
        // eslint-disable-next-line no-undef
        if (AP?.request) {
            // eslint-disable-next-line no-undef
            AP.request('/rest/agile/1.0/board', {
                success: function (response) {
                    const payload = JSON.parse(response)
                    setBoardOptions(payload.values)
                    setBoardValue(payload.values[0].id)
                },
            })
        }
    }, [])

    const getBacklog = useCallback(async () => {
        // eslint-disable-next-line no-undef
        if (AP?.request) {
            // eslint-disable-next-line no-undef
            await AP.request({
                url: `/rest/agile/1.0/board/${boardValue}/backlog`,
                type: 'GET',
                contentType: 'application/json',
            }).then((data) => {
                const payload = JSON.parse(data.body)
                setBacklogOptions(payload.issues)
                setBacklogValue(payload.issues[0].id)
            })
        }
    }, [boardValue])

    useEffect(() => {
        getBacklog()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boardValue])

    useEffect(() => {
        getStatuses()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [backlogValue])

    const getStatuses = useCallback(async () => {
        // eslint-disable-next-line no-undef
        if (AP?.request && backlogValue.length) {
            // eslint-disable-next-line no-undef
            await AP.request({
                url: `/rest/api/3/issue/${backlogValue}/transitions/`,
                type: 'GET',
                contentType: 'application/json',
            }).then((data) => {
                const payload = JSON.parse(data.body)
                setStatusOptions(payload.transitions)
                setStatusValue(payload.transitions[0]?.id)
            })
        }
    }, [setStatusOptions, backlogValue])

    const changeStatus = useCallback(() => {
        // eslint-disable-next-line no-undef
        if (AP?.request) {
            const data = {
                transition: {
                    id: statusValue,
                },
            }
            // eslint-disable-next-line no-undef
            AP.request({
                url: `/rest/api/3/issue/${backlogValue}/transitions`,
                type: 'POST',
                contentType: 'application/json',
                data: JSON.stringify(data),
            }).then(() => {
                getBacklog()
            })
        }
    }, [backlogValue, statusValue, getBacklog])

    const handleSave = useCallback(() => {
        // eslint-disable-next-line no-undef
        if (AP?.request) {
            const data = {
                value: estimateValue,
            }

            // eslint-disable-next-line no-undef
            AP.request({
                url: `/rest/agile/1.0/issue/${backlogValue}/estimation?boardId=${boardValue}`,
                type: 'PUT',
                contentType: 'application/json',
                data: JSON.stringify(data),
            })
                .then(() => {
                    toast.success(`Estimate set of ${estimateValue}!`)
                    setEstimateValue('')
                })
                .catch((e) =>
                    toast.error(
                        `Estimate could not be set, please do this directly on the ticket`
                    )
                )
            changeStatus()
        }
    }, [boardValue, backlogValue, estimateValue, changeStatus])

    // eslint-disable-next-line no-undef
    if (AP?.request) {
        return (
            <div className="AdminDash w-full relative" style={{ height: 200 }}>
                <div
                    className="flex"
                    style={{
                        width: '90%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        overflow: 'auto',
                        maxHeight: '100%',
                    }}
                >
                    <div
                        style={{ height: 200, display: 'flex', margin: 'auto' }}
                    >
                        <div>
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <label
                                        for="boards"
                                        class="block mb-2 text-sm font-medium text-white dark:text-white"
                                    >
                                        Select a Board
                                    </label>
                                    <select
                                        value={boardValue}
                                        onChange={(e) =>
                                            setBoardValue(e.target.value)
                                        }
                                        id="boards"
                                        class="bg-gray-50 border mb-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    >
                                        {boardOptions?.map((board) => (
                                            <option value={board.id} selected>
                                                {board.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="ml-4">
                                    <label
                                        for="backlog"
                                        class="block mb-2 text-sm font-medium text-white dark:text-white"
                                    >
                                        Select a Backlog Item
                                    </label>
                                    <select
                                        value={backlogValue}
                                        onChange={(e) =>
                                            setBacklogValue(e.target.value)
                                        }
                                        id="backlog"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    >
                                        {backlogOptions?.map((backlogItem) => (
                                            <option
                                                value={backlogItem.id}
                                                selected
                                            >
                                                {backlogItem.key} -{' '}
                                                {backlogItem.fields.summary}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <label
                                        for="estimate"
                                        class="block mb-2 text-sm font-medium text-white dark:text-white"
                                    >
                                        Estimate:
                                    </label>
                                    <input
                                        type="number"
                                        onChange={(e) =>
                                            setEstimateValue(e.target.value)
                                        }
                                        value={estimateValue}
                                        id="estimate"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    />
                                </div>
                                <div className="ml-4">
                                    <label
                                        for="backlog"
                                        class="block mb-2 text-sm font-medium text-white dark:text-white"
                                    >
                                        Select a Status
                                    </label>
                                    <select
                                        value={statusValue}
                                        onChange={(e) =>
                                            setStatusValue(e.target.value)
                                        }
                                        id="backlog"
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    >
                                        {statusOptions?.map((statusItem) => (
                                            <option
                                                value={statusItem.id}
                                                selected
                                            >
                                                {statusItem.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div style={{ marginLeft: 32 }}>
                                    <button
                                        style={{ marginTop: 30 }}
                                        disabled={!estimateValue.length}
                                        onClick={() => handleSave()}
                                        class="bg-blue-500 saveButton hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                    >
                                        Submit Edits to Issue
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminDash
