import React from 'react'
import Banner from '../banner/Banner'
import Card2 from '../home/images/Cards2.png'
import Card3 from '../home/images/Cards3.png'
import Footer from '../page/Footer'

const Home = () => {
    return (
        <div>
            <Banner
                title={'Scrum Poker Made Easy For Agile Teams'}
                description={
                    'Make clear estimates as a team for your Agile Tickets in a productive environment'
                }
            />
            <div className="greyBackground-card">
                <div className="greyBackground-title">
                    Keep Team Planning Interesting
                </div>

                <div className="flex">
                    <div className="showcaseImage" style={{ marginLeft: 0 }}>
                        <img src={Card2} alt="Voting session in Scrum Poker" />
                    </div>
                    <div
                        className="greyBackground-description"
                        style={{ marginTop: 120 }}
                    >
                        Keep your team connected where ever they are, remote,
                        hybrid or in the office. Assign teams in a productive
                        and enjoyable environment.
                    </div>
                </div>

                <div className="greyBackground-title" style={{ marginTop: 30 }}>
                    Real-Time Estimates - Made Easy
                </div>

                <div className="flex" style={{ paddingBottom: 80 }}>
                    <div
                        className="greyBackground-description"
                        style={{ marginTop: 120 }}
                    >
                        Share the Link to your Scrum Poker Room and Your Team
                        can Join and Leave at their ease.
                    </div>

                    <div className="showcaseImage" style={{ marginRight: 0 }}>
                        <img src={Card3} alt="Votes counted in Scrum Poker" />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Home
