import React, {
    useState,
    useContext,
    useEffect,
    useCallback,
    useRef,
} from 'react'
import Table from '../table/Table'
import { get } from 'lodash-es'
import PlayerDash from '../player/PlayerDash'
import { useSelector, useDispatch } from 'react-redux'
import {
    setUsername,
    addExistingPlayers,
    updatePlayerNumbers,
    setUserDetails,
    playerLeft,
} from './actions/actions'
import { WebSocketContext } from '../../socket/WebSocket'

const Room = () => {
    const deck = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]

    // creator is host

    // host selects deck

    const usernameInput = useRef()
    // const [msgInput, setMsgInput] = useState("");

    const room = useSelector((state) => state.room)
    // Create Link Below
    const rootDomain = 'https://scrumpokeronline.co'
    const shareUrl = `${rootDomain}/game?room=${room.id}`
    const teams = useSelector((state) => state.teams)
    const isAdmin = useSelector((state) => state.isAdmin)
    const username = useSelector((state) => state.username)
    const currentPlayers = useSelector((state) => state.currentPlayers)
    const user = useSelector((state) => state.user)
    const [team, setTeam] = useState('')
    const [hideAdminButton, setHideAdminButton] = useState(false)
    // const chats = useSelector(state => state.chatLog);
    const dispatch = useDispatch()
    const ws = useContext(WebSocketContext)
    const { socket } = ws

    useEffect(() => {
        // eslint-disable-next-line no-undef
        if (AP?.request) {
            // eslint-disable-next-line no-undef
            AP.request({
                url: '/rest/api/3/myself',
                success: function (response) {
                    response = JSON.parse(response)
                    dispatch(setUserDetails({ user: response }))
                },
            })
        }
    }, [])

    const enterRoom = useCallback(() => {
        if (isAdmin || get(usernameInput, 'current.value.length', 0) > 0) {
            const currentPlayer = {
                id: 1,
                name: isAdmin ? 'admin' : usernameInput.current.value,
                number: '',
                team: team,
                isAdmin: isAdmin,
            }

            if (user) {
                currentPlayer.profile = user?.avatarUrls?.['32x32']
            }

            dispatch(
                setUsername(
                    isAdmin ? 'admin' : usernameInput.current.value,
                    currentPlayer
                )
            )
            ws.playerJoined(room.id, currentPlayer, teams)
            const url = new URL(window.location)
            url.searchParams.set('room', room.id)
            window.history.pushState(null, '', url.toString())
        }
        if (isAdmin) {
            setHideAdminButton(true)
        }
    }, [ws, dispatch, room.id, usernameInput, team, teams, isAdmin, user])

    useEffect(() => {
        if (socket) {
            socket.on('event://player-joined', (msg) => {
                if (room.id === msg.roomId) {
                    dispatch(addExistingPlayers(msg.players))
                }
            })
            socket.on('event://get-message', (msg) => {
                const response = JSON.parse(msg, 'get')
                if (room.id === response.roomId) {
                    dispatch(updatePlayerNumbers(response))
                }
                // dispatch(addExistingPlayers(msg))
            })
            socket.on('event://player-leaving', (msg) => {
                ws.playerLeft(room.id, msg)
                dispatch(playerLeft(msg))
            })

            return () => {
                socket.disconnect()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const chunkSize = 4
    const chunks = []
    for (let i = 0; i < currentPlayers.length; i += chunkSize) {
        const chunk = currentPlayers.slice(i, i + chunkSize)
        // do whatever
        chunks.push(chunk)
    }

    const handleTeamChange = (e) => {
        setTeam(e.target.value)
    }

    const onKeyPress = (event) => {
        const regex = /^[a-zA-Z_ ]+$/
        if (get(usernameInput, 'current.value.length', 0) < 15) {
            if (regex.test(event.key)) {
                return event.key
            } else {
                event.preventDefault()
            }
        } else {
            event.preventDefault()
        }
    }

    const returnLink = () => {
        return `${rootDomain}/game?room=${room.id}`
    }

    return (
        <div className="prose">
            <>
                <h1 className="text-center mb-10 capitalize text-xl font-bold mt-4">
                    {room.name}
                </h1>
                <div
                    style={{
                        display: 'block',
                        width: 260,
                        fontSize: 12,
                        textAlign: 'center',
                        position: 'absolute',
                        top: 90,
                        right: 20,
                    }}
                >
                    <label>Share Link To Add More Players</label>
                    <div class="border-2 border-gray-200 flex justify-between items-center mt-2 mb-4 Share-box">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            class="fill-gray-500 ml-2"
                        >
                            <path d="M8.465 11.293c1.133-1.133 3.109-1.133 4.242 0l.707.707 1.414-1.414-.707-.707c-.943-.944-2.199-1.465-3.535-1.465s-2.592.521-3.535 1.465L4.929 12a5.008 5.008 0 0 0 0 7.071 4.983 4.983 0 0 0 3.535 1.462A4.982 4.982 0 0 0 12 19.071l.707-.707-1.414-1.414-.707.707a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.122-2.121z"></path>
                            <path d="m12 4.929-.707.707 1.414 1.414.707-.707a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.122 2.121c-1.133 1.133-3.109 1.133-4.242 0L10.586 12l-1.414 1.414.707.707c.943.944 2.199 1.465 3.535 1.465s2.592-.521 3.535-1.465L19.071 12a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0z"></path>
                        </svg>

                        <input
                            style={{ border: 0, fontSize: 11 }}
                            id="shareLink"
                            class="w-full outline-none bg-transparent"
                            type="text"
                            placeholder="link"
                            value={shareUrl}
                        />

                        <button
                            class="bg-indigo-500 text-white rounded text-sm py-2 px-5 mr-2 hover:bg-indigo-600"
                            onClick={() => {
                                document.querySelector('#shareLink').select()
                                document.execCommand('copy')
                            }}
                        >
                            Copy
                        </button>
                    </div>
                </div>
                {!username && !isAdmin ? (
                    <>
                        <div className="m-auto w-80">
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    Set Your Name
                                </label>
                                <input
                                    ref={usernameInput}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="text"
                                    placeholder="Enter username"
                                    onKeyPress={(event) => onKeyPress(event)}
                                />
                            </div>
                        </div>
                        {teams.length > 1 && (
                            <div className="m-auto w-80">
                                <div className="mb-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2">
                                        Pick your Team
                                    </label>
                                    {teams.map((team) => {
                                        return (
                                            <>
                                                <div className="flex items-center mb-4">
                                                    <input
                                                        id="disabled-radio-1"
                                                        onChange={(e) =>
                                                            handleTeamChange(e)
                                                        }
                                                        type="radio"
                                                        value={team}
                                                        name="team-radio"
                                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                    />
                                                    <label
                                                        htmlFor="disabled-radio-1"
                                                        className="ml-2 text-sm font-medium"
                                                    >
                                                        {team}
                                                    </label>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        )}
                        <div className="m-auto w-80">
                            <button
                                type="button"
                                disabled={
                                    team === '' &&
                                        teams.length > 1 &&
                                        get(
                                            usernameInput,
                                            'current.value.length',
                                            0
                                        ) < 1
                                        ? true
                                        : false
                                }
                                className={`${team === '' && teams.length > 1
                                        ? 'disabledButton'
                                        : 'bg-blue-500 hover:bg-blue-700'
                                    } mt-2 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-25 disabled:bg-grey`}
                                onClick={enterRoom}
                            >
                                Enter Room
                            </button>
                        </div>
                    </>
                ) : (
                    !hideAdminButton &&
                    isAdmin && (
                        <div>
                            <div className="m-auto w-80">
                                <button
                                    type="button"
                                    className={`block ml-auto mr-auto bg-blue-500 hover:bg-blue-700 mt-2 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-25 disabled:bg-grey`}
                                    onClick={enterRoom}
                                >
                                    Enter Room as Admin
                                </button>
                            </div>
                        </div>
                    )
                )}
                {(username || (isAdmin && hideAdminButton)) && (
                    <>
                        <Table
                            topRow={get(chunks, '[0]', [])}
                            leftRow={get(chunks, '[1]', [])}
                            bottomRow={get(chunks, '[2]', [])}
                            rightRow={get(chunks, '[3]', [])}
                        />
                        <PlayerDash deck={deck} />
                    </>
                )}
            </>
        </div>
    )
}

export default Room
