import React from 'react'
import Hat from '../hat/Hat'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

const Card = ({
    playersNumber,
    team,
    name,
    profile,
    number,
    sendMessage,
    cardPicked,
    flipped,
    staticCard,
}) => {
    let userProfilePhoto = ''
    if (profile) {
        userProfilePhoto = profile
    }

    if (staticCard) {
        return (
            <div style={{ padding: 10 }}>
                {team && <Hat team={team} />}
                <div
                    className={`text-center pt-8 Card ${
                        playersNumber === number ? 'active' : ''
                    } ${flipped ? 'flipped' : ''} drop-shadow-lg`}
                >
                    {!flipped && number}
                </div>
                <p className="text-center mb-2">
                    {String(name).replace(/\s/g, '')}
                </p>{' '}
                <div className="profileCircleCard">
                    {userProfilePhoto ? (
                        <img alt="User Profile" src={userProfilePhoto} />
                    ) : (
                        <FontAwesomeIcon icon={faUser} />
                    )}
                </div>
            </div>
        )
    }

    return (
        <div>
            <button
                disabled={cardPicked}
                className={`Card ${playersNumber === number ? 'active' : ''} ${
                    flipped ? 'flipped' : ''
                } drop-shadow-lg`}
                onClick={() => {
                    sendMessage({ cardNumber: number })
                }}
            >
                {!flipped && number}
            </button>{' '}
        </div>
    )
}

export default Card
