// actions.js
import axios from 'axios'
import { API_BASE } from '../../../config'

// These are our action types
export const CREATE_ROOM_REQUEST = 'CREATE_ROOM_REQUEST'
export const CREATE_ROOM_SUCCESS = 'CREATE_ROOM_SUCCESS'
export const CREATE_ROOM_ERROR = 'CREATE_ROOM_ERROR'
export const JOIN_ROOM_REQUEST = 'JOIN_ROOM_REQUEST'
export const JOIN_ROOM_SUCCESS = 'JOIN_ROOM_SUCCESS'
export const JOIN_ROOM_ERROR = 'JOIN_ROOM_ERROR'
export const SET_TEAMS = 'SET_TEAMS'
export const SET_IS_ADMIN = 'SET_IS_ADMIN'

// Now we define actions
export function createRoomRequest() {
    return {
        type: CREATE_ROOM_REQUEST,
    }
}

export function setTeams(teams) {
    return {
        type: SET_TEAMS,
        teams,
    }
}

export function setIsAdmin(isAdmin) {
    return {
        type: SET_IS_ADMIN,
        isAdmin,
    }
}

export function createRoomSuccess(payload) {
    return {
        type: CREATE_ROOM_SUCCESS,
        payload,
    }
}

export function createRoomError(error) {
    return {
        type: CREATE_ROOM_ERROR,
        error,
    }
}

export function createRoom(roomName) {
    return async function (dispatch) {
        dispatch(createRoomRequest())
        const config = {
            headers: {
                'ngrok-skip-browser-warning': 'true',
            },
        }
        try {
            const response = await axios.get(
                `${API_BASE}/api/room?name=${roomName}`,
                config
            )
            dispatch(createRoomSuccess(response.data))
        } catch (error) {
            dispatch(createRoomError(error))
        }
    }
}

export function joinRoomRequest() {
    return {
        type: JOIN_ROOM_REQUEST,
    }
}

export function joinRoomSuccess(payload) {
    return {
        type: JOIN_ROOM_SUCCESS,
        payload,
    }
}

export function joinRoomError(error) {
    return {
        type: JOIN_ROOM_ERROR,
        error,
    }
}

export function joinRoom(roomId) {
    return async function (dispatch) {
        dispatch(joinRoomRequest())
        const config = {
            headers: {
                'ngrok-skip-browser-warning': 'true',
            },
        }
        try {
            const response = await axios.get(
                `${API_BASE}/api/room/${roomId}`,
                config
            )
            dispatch(joinRoomSuccess(response.data))
        } catch (error) {
            dispatch(joinRoomError(error))
        }
    }
}
