/* eslint-disable import/no-anonymous-default-export */
import React, { createContext } from 'react'
import io from 'socket.io-client'
import { WS_BASE } from '../config'
import { useDispatch } from 'react-redux'
import {
    revealCards,
    playerJoinedFunc,
    retrievePlayerListFunc,
    sendPlayerDetailsFunc,
} from './actions/actions'

const WebSocketContext = createContext(null)

export { WebSocketContext }

export default ({ children }) => {
    let socket
    let ws = {}

    const dispatch = useDispatch()

    const sendMessage = (roomId, message) => {
        const payload = {
            roomId: roomId,
            number: message,
        }

        /*         let currentPlayer = state.currentPlayer;
        currentPlayer.number = action.update.number.number;
        const arrayOfPlayers = state.players;
        const objIndex = arrayOfPlayers.findIndex((player => player.id === state.currentPlayer.id));
        arrayOfPlayers[objIndex].number = action.update.number.number; */
        socket.emit('event://send-message', JSON.stringify(payload))
    }

    const revealCardsMessage = (roomId, reveal) => {
        const payload = {
            roomId: roomId,
            reveal: reveal,
        }
        socket.emit('event://reveal-cards', JSON.stringify(payload))
        dispatch(revealCards(payload))
    }

    const resetGame = (roomId, currentPlayer, currentPlayers) => {
        const payload = {
            roomId: roomId,
            currentPlayer,
            currentPlayers,
        }
        socket.emit('event://reset-game', JSON.stringify(payload))
    }

    const playerJoined = (roomId, player, teams) => {
        const payload = {
            roomId: roomId,
            player: player,
            ...(teams && { teams: teams }), // add only if teams exists
        }

        socket.emit('event://player-joined', JSON.stringify(payload))
        dispatch(playerJoinedFunc(payload))
    }

    const playerLeft = (roomId, player) => {
        const payload = {
            roomId: roomId,
            player: player,
        }
        socket.emit('event://player-left', JSON.stringify(payload))
    }

    const sendPlayerDetails = (roomId, player) => {
        const payload = {
            roomId: roomId,
            player: player,
        }
        socket.emit('event://send-player-details', JSON.stringify(payload))
        dispatch(sendPlayerDetailsFunc(payload))
    }

    const retrievePlayerList = (roomId, players) => {
        const payload = {
            roomId: roomId,
            players: players,
        }
        socket.emit('event://retrieve-player-list', JSON.stringify(payload))
        dispatch(retrievePlayerListFunc(payload))
    }

    if (!socket) {
        socket = io(WS_BASE + '/rooms', {
            extraHeaders: {
                'ngrok-skip-browser-warning': 'true',
            },
        })
    }

    ws = {
        socket: socket,
        sendMessage,
        revealCardsMessage,
        sendPlayerDetails,
        retrievePlayerList,
        playerJoined,
        playerLeft,
        resetGame,
    }

    return (
        <WebSocketContext.Provider value={ws}>
            {children}
        </WebSocketContext.Provider>
    )
}
