export const CARDS_REVEALED = 'CARDS_REVEALED'
export const RESET_GAME = 'RESET_GAME'

export function cardsRevealed() {
      return {
            type: CARDS_REVEALED,
      }
}

export function resetAction(msg) {
      return {
            type: RESET_GAME,
            ...msg,
      }
}
