import React from "react";

const Hat = ({team}) => {

    return (
        <div className={`Hat-default Hat-${team}`}>{team}</div>
    )

};

export default Hat;
