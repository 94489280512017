export const SET_USERNAME = 'SET_USERNAME'
export const ADD_EXISTING_PLAYERS = 'ADD_EXISTING_PLAYERS'
export const UPDATE_PLAYER_NUMBERS = 'UPDATE_PLAYER_NUMBERS'
export const PLAYER_LEFT = 'PLAYER_LEFT'
export const SET_USER = 'SET_USER'

export function setUserDetails(payload) {
    return {
        type: SET_USER,
        user: payload.user,
        payload,
    }
}

export function playerLeft(playerId) {
    return {
        type: PLAYER_LEFT,
        playerId,
    }
}

export function setUsername(username, currentPlayer) {
    return {
        type: SET_USERNAME,
        username,
        currentPlayer,
    }
}

export function addExistingPlayers(playerList) {
    return {
        type: ADD_EXISTING_PLAYERS,
        playerList,
    }
}

export function updatePlayerNumbers(playerList) {
    return {
        type: UPDATE_PLAYER_NUMBERS,
        playerList,
    }
}
