import React, { useContext, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { cardsRevealed, resetAction } from './actions/actions'
import Card from '../card/Card'
import { WebSocketContext } from '../../socket/WebSocket'
import Status from '../status/Status'

const Table = ({ leftRow, topRow, bottomRow, rightRow, pickedCard }) => {
    //  const dispatch = useDispatch();

    const ws = useContext(WebSocketContext)

    const dispatch = useDispatch()

    const showRevealButton = useSelector((state) => state.showRevealButton)
    const showCards = useSelector((state) => state.showCards)
    const room = useSelector((state) => state.room)
    const teams = useSelector((state) => state.teams)
    const currentPlayers = useSelector((state) => state.currentPlayers)
    const currentPlayer = useSelector((state) => state.currentPlayer)

    const revealCardsAction = () => {
        ws.revealCardsMessage(room.id, {
            reveal: true,
        })
    }

    const resetGame = () => {
        ws.resetGame(room.id, currentPlayer, currentPlayers)
    }

    const getRange = () => {
        let range = []
        currentPlayers.forEach((player) => {
            range.push(parseInt(player.number))
        })

        let max = Math.max.apply(Math, range)
        let min = Math.min.apply(Math, range)
        return max - min
    }

    const getAverage = (team = '') => {
        let total = 0
        let playerCount = 0
        currentPlayers.forEach((player) => {
            if (player.number !== '' && team === player.team) {
                playerCount++
                total += parseInt(player.number)
            }
        })

        return Math.round((total / playerCount) * 10) / 10
    }

    useEffect(() => {
        const { socket } = ws
        if (socket) {
            socket.on('event://cards-revealed', (msg) => {
                if (room.id === msg.roomId) {
                    dispatch(cardsRevealed(msg.body))
                }
            })

            socket.on('event://reset-game', (msg) => {
                if (room.id === msg.roomId) {
                    dispatch(resetAction(msg))
                }
            })
        }

        return () => {
            socket.disconnect()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="Table-container drop-shadow-lg">
            <div className="Table-left">
                {leftRow.map((player) => (
                    <Card
                        key={player.id}
                        profile={player.profile}
                        staticCard
                        name={player.name}
                        team={player.team}
                        number={player.number}
                        flipped={player.number && !showCards ? true : false}
                    />
                ))}
            </div>
            <div className="Table-top">
                {' '}
                {topRow.map((player) => (
                    <Card
                        key={player.id}
                        staticCard
                        profile={player.profile}
                        name={player.name}
                        team={player.team}
                        number={player.number}
                        flipped={player.number && !showCards ? true : false}
                    />
                ))}
            </div>
            <div className="Table">
                <div className="Table-status">
                    <Status
                        teams={teams}
                        resetAction={resetGame}
                        showCards={showCards}
                        getRange={getRange}
                        getAverage={getAverage}
                        revealCardsAction={revealCardsAction}
                        showRevealButton={showRevealButton}
                        isAdmin={currentPlayer.admin}
                    />
                </div>
            </div>
            <div className="Table-right">
                {' '}
                {rightRow.map((player) => (
                    <Card
                        key={player.id}
                        staticCard
                        team={player.team}
                        profile={player.profile}
                        name={player.name}
                        number={player.number}
                        flipped={player.number && !showCards ? true : false}
                    />
                ))}
            </div>
            <div className="Table-bottom">
                {' '}
                {bottomRow.map((player) => (
                    <Card
                        key={player.id}
                        staticCard
                        profile={player.profile}
                        team={player.team}
                        name={player.name}
                        number={player.number}
                        flipped={player.number && !showCards ? true : false}
                    />
                ))}
            </div>
        </div>
    )
}

export default Table
