/* eslint-disable default-case */
// reducers.js

import {
    CREATE_ROOM_SUCCESS,
    JOIN_ROOM_SUCCESS,
    SET_TEAMS,
    SET_IS_ADMIN,
} from '../components/create/actions/actions'
import { REVEAL_CARDS, SEND_PLAYER_DETAILS } from '../socket/actions/actions'
import { CHANGE_CARD_NUMBER } from '../components/player/actions/actions'
import { CARDS_REVEALED, RESET_GAME } from '../components/table/actions/actions'
import { LOGGED_IN_USER } from '../components/theme/actions/actions'
import {
    SET_USERNAME,
    ADD_EXISTING_PLAYERS,
    UPDATE_PLAYER_NUMBERS,
    PLAYER_LEFT,
    SET_USER,
} from '../components/room/actions/actions'

const initialState = {
    room: null,
    admin: false,
    chatLog: [],
    username: null,
    players: [],
    currentPlayer: {},
    showRevealButton: false,
    newPlayerHasJoined: false,
    loggedInUser: null,
    showCards: false,
    currentPlayers: [],
    isAdmin: false,
    teams: [],
}

export default function chatReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState
    }

    switch (action.type) {
        case CREATE_ROOM_SUCCESS:
            return {
                ...state,
                room: action.payload,
                admin: true,
            }

        case CHANGE_CARD_NUMBER:
            if (state.room !== null && action.roomId === state.room.id) {
                const currentPlayerUpdated = { ...action.payload.currentPlayer }
                currentPlayerUpdated.number = action.payload.cardNumber

                return {
                    ...state,
                    currentPlayer: currentPlayerUpdated,
                }
            }
            return {
                ...state,
            }

        case SET_USER:
            return {
                ...state,
                user: action.user,
            }

        case JOIN_ROOM_SUCCESS:
            if (Object.keys(action.payload).length === 0) {
                return {
                    ...state,
                }
            }

            return {
                ...state,
                room: action.payload,
                teams: action.payload.teams,
                currentPlayers: [...action.payload.playerList],
                newPlayerHasJoined: true,
            }

        case ADD_EXISTING_PLAYERS:
            return {
                ...state,
                currentPlayers: [...action.playerList],
            }

        case LOGGED_IN_USER:
            if (action.user === null) {
                return {
                    ...state,
                    loggedInUser: null,
                }
            }
            return {
                ...state,
                loggedInUser: action.user,
            }

        case SEND_PLAYER_DETAILS:
            return {
                ...state,
                currentPlayers: [...state.currentPlayers, state.currentPlayer],
                newPlayerHasJoined: false,
            }

        case REVEAL_CARDS:
            return {
                ...state,
                showCards: true,
            }

        case CARDS_REVEALED:
            if (state.showCards !== true) {
                return {
                    ...state,
                    showCards: true,
                }
            }
            return {
                ...state,
            }

        case RESET_GAME:
            let currentPlayerReset = { ...state.currentPlayer }
            currentPlayerReset.number = ''
            return {
                ...state,
                showRevealButton: false,
                newPlayerHasJoined: false,
                currentPlayer: currentPlayerReset,
                currentPlayers: [...action.playerList],
                showCards: false,
            }

        case SET_TEAMS:
            return {
                ...state,
                teams: action.teams,
            }

        case SET_IS_ADMIN:
            return {
                ...state,
                isAdmin: action.isAdmin,
            }

        case SET_USERNAME:
            let currentPlayer = action.currentPlayer
            const random = (Math.random() * 6).toString(36).substring(7)
            const random2 = (Math.random() * 3).toString(36).substring(7)
            currentPlayer.id = `${random}${random2}`

            if (action.currentPlayer.isAdmin) {
                return {
                    ...state,
                }
            }
            return {
                ...state,
                username: action.username,
                currentPlayer: action.currentPlayer,
                currentPlayers: [...state.currentPlayers, currentPlayer],
            }

        case PLAYER_LEFT:
            const newArray = state.currentPlayers.filter(
                (player) => player.socketId !== action.playerId
            )
            return {
                ...state,
                currentPlayers: newArray,
            }

        case UPDATE_PLAYER_NUMBERS:
            if (
                state.room !== null &&
                action.playerList.roomId === state.room.id
            ) {
                return {
                    ...state,
                    currentPlayers: [...action.playerList.playerList],
                    showRevealButton: true,
                }
            }
            return {
                ...state,
            }
    }

    return state
}
