import React, { useContext, useState, useEffect } from 'react'
import Card from '../card/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { changeCardNumber } from './actions/actions'
import { WebSocketContext } from '../../socket/WebSocket'
import AdminDash from './AdminDash'

const PlayerDash = ({ deck }) => {
    const room = useSelector((state) => state.room)
    const currentPlayer = useSelector((state) => state.currentPlayer)
    const isAdmin = useSelector((state) => state.isAdmin)
    const ws = useContext(WebSocketContext)
    const dispatch = useDispatch()
    const [cardPicked, setCardPicked] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const [clearActive, setClearActive] = useState(false)
    const [dashHidden, setDashHidden] = useState(false)

    useEffect(() => {
        const { socket } = ws
        if (socket) {
            socket.on('event://reset-game', (msg) => {
                if (room.id === msg.roomId) {
                    setCardPicked(false)
                    setClearActive(true)
                }
            })
        }

        return () => {
            socket.disconnect()
            setClearActive(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const sendMessage = ({ cardNumber }) => {
        setCardPicked(true)
        ws.sendMessage(room.id, {
            currentPlayer: currentPlayer,
            number: cardNumber,
        })

        dispatch(
            changeCardNumber({ currentPlayer, cardNumber, roomId: room.id })
        )
    }

    /*     const getOriginUrl = () => {
        const href = document.location.href
        const referrer = document.referrer
        // Detect if you're inside an iframe
        if (window.parent !== window) {
            // Take referrer as origin
            return referrer
        } else {
            // Take href
            return href
        }
    } */

    return (
        <div
            className={`${
                dashHidden ? 'hideDash' : ''
            } PlayerDash ${isAdmin} adminDash`}
        >
            <button
                className="PlayerDash-toggle bg-gray-200!"
                onClick={() => setDashHidden(!dashHidden)}
            >
                {dashHidden ? (
                    <FontAwesomeIcon icon={faArrowUp} />
                ) : (
                    <FontAwesomeIcon icon={faArrowDown} />
                )}
            </button>
            {!isAdmin ? (
                <div style={{display: 'flex', overflowX: 'scroll'}}>
                    {deck.map((card) => {
                        return (
                            <Card
                                key={card}
                                playersNumber={currentPlayer.number}
                                number={card}
                                sendMessage={sendMessage}
                                cardPicked={cardPicked}
                            />
                        )
                    })}
                </div>
            ) : (
                <>
                    <AdminDash />
                </>
            )}
        </div>
    )
}

export default PlayerDash
