import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setIsAdmin, setTeams, createRoom, joinRoom } from './actions/actions'
import Room from '../room/Room'
import Tooltip from '../tooltip/Tooltip'

const Create = () => {
    const [roomName, setRoomName] = useState('')
    const [password, setPassword] = useState('')
    const [licenced, setLicenced] = useState(false)
    const [access, setAccess] = useState(false)
    const currentRoom = useSelector((state) => state.room)
    const isAdmin = useSelector((state) => state.isAdmin)
    const teams = useSelector((state) => state.teams)
    const dispatch = useDispatch()
    useEffect(() => {
        // eslint-disable-next-line no-undef
        if (AP?.request) {
            // eslint-disable-next-line no-undef
            AP.request({
                url: `/rest/atlassian-connect/1/addons/scrumpokeronline`,
                type: 'GET',
                contentType: 'application/json',
            }).then((data) => {
                const payload = JSON.parse(data.body)
                payload?.license?.active && setLicenced(true)
            })
        }
    }, [])

    const urlParams = new URLSearchParams(window.location.search)
    const roomQuery = urlParams.get('room')

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search)
        const roomId = searchParams.get('room')

        dispatch(joinRoom(roomId))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.search])

    const handleTeamCheckbox = (e) => {
        const index = teams.indexOf(e.target.value)
        const newTeams = [...teams]
        if (index > -1) {
            // only splice array when item is found
            newTeams.splice(index, 1) // 2nd parameter means remove one item only
            dispatch(setTeams(newTeams))
        } else {
            dispatch(setTeams([...teams, e.target.value]))
        }
    }

    const checkPassword = () => {
        if (password === process.env.REACT_APP_ADMIN_PASS) {
            setAccess(true)
        }
    }

    return (
        <div>
            {!currentRoom &&
                // eslint-disable-next-line no-undef
                !AP?.request &&
                !access &&
                roomQuery === null &&
                !window?.frameElement && (
                    <div className="m-auto w-80 mt-2">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                            Password
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button
                            type="button"
                            className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            onClick={() => checkPassword()}
                        >
                            Submit
                        </button>
                    </div>
                )}

            {
                // eslint-disable-next-line no-undef
                (!currentRoom && AP?.request && licenced) ||
                (access && !currentRoom) ? (
                    <div>
                        <div
                            className="Banner-title"
                            style={{
                                textAlign: 'center',
                                marginTop: 20,
                                marginBottom: 20,
                            }}
                        >
                            Create A Game
                        </div>
                        <div className="m-auto w-80 mt-2">
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    Room Name?
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="text"
                                    placeholder="Room name"
                                    value={roomName}
                                    onChange={(e) =>
                                        setRoomName(e.target.value)
                                    }
                                />
                                <div className="mt-2 flex">
                                    <label className="block text-gray-700 text-sm font-bold dark:text-gray-300 mr-2">
                                        Pick Teams?
                                    </label>
                                    <Tooltip
                                        message={
                                            'This is optional - if you dont everyone will be assigned to one team'
                                        }
                                    />
                                </div>

                                <div className="flex items-center">
                                    <input
                                        id="default-checkbox"
                                        type="checkbox"
                                        value="UI"
                                        onChange={(e) => handleTeamCheckbox(e)}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="default-checkbox"
                                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        UI
                                    </label>
                                </div>
                                <div className="flex items-center">
                                    <input
                                        id="checked-checkbox"
                                        type="checkbox"
                                        value="API"
                                        onChange={(e) => handleTeamCheckbox(e)}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-checkbox"
                                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        API
                                    </label>
                                </div>
                                <div className="flex items-center">
                                    <input
                                        id="checked-checkbox"
                                        type="checkbox"
                                        value="QA"
                                        onChange={(e) => handleTeamCheckbox(e)}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-checkbox"
                                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        QA
                                    </label>
                                </div>
                                <div className="flex items-center">
                                    <input
                                        id="checked-checkbox"
                                        type="checkbox"
                                        value="UX"
                                        onChange={(e) => handleTeamCheckbox(e)}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-checkbox"
                                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        UX
                                    </label>
                                </div>
                                <div className="mt-2">
                                    <div className="flex">
                                        <label
                                            htmlFor="checked-checkbox"
                                            className="block text-gray-700 text-sm font-bold mr-2"
                                        >
                                            Admin?
                                        </label>
                                        <Tooltip
                                            message={
                                                'You wont participate - but can reveal cards etc.'
                                            }
                                        />
                                    </div>
                                    <label
                                        htmlFor="adminToggle"
                                        className="inline-flex relative items-center cursor-pointer"
                                    >
                                        <input
                                            onChange={() =>
                                                dispatch(setIsAdmin(!isAdmin))
                                            }
                                            checked={isAdmin}
                                            type="checkbox"
                                            value=""
                                            id="adminToggle"
                                            className="sr-only peer"
                                        />
                                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                    </label>
                                </div>
                                <button
                                    type="button"
                                    className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    onClick={() =>
                                        dispatch(createRoom(roomName))
                                    }
                                >
                                    Create
                                </button>

                                <p
                                    style={{
                                        fontSize: 12,
                                        marginTop: 10,
                                        textAlign: 'center',
                                    }}
                                >
                                    Multiple Rooms now Possible 🥳
                                </p>
                            </div>
                            {/*                     <div>
                        <iframe src="https://github.com/sponsors/jobrien874/button" title="Sponsor jobrien874" height="35" width="116" style={{border: 0}}></iframe>
                    </div> */}
                        </div>
                    </div>
                ) : null
            }

            {
                // eslint-disable-next-line no-undef
                !currentRoom && AP?.request && !licenced && (
                    <>
                        <div
                            className="Banner-title"
                            style={{
                                textAlign: 'center',
                                marginTop: 20,
                                marginBottom: 20,
                            }}
                        >
                            Licence Expired
                        </div>
                        <div>
                            <p className="text-center">
                                Please make sure that you have a Valid Licence
                                in Order to Create a Room.
                            </p>
                        </div>
                    </>
                )
            }

            {currentRoom && <Room teams={teams} />}
        </div>
    )
}

export default Create
