import React from 'react'
import Banner from '../banner/Banner'
import Room from '../documentation/images/Room.png'
import Room2 from '../documentation/images/Room2.png'
import Room3 from '../documentation/images/Room3.png'
import Footer from '../page/Footer'

const Documentation = () => {
    return (
        <div>
            <Banner
                title={'Guide to Use ScrumPoker'}
                description={'A Simple "How To" on using ScrumPoker'}
            />
            <div className="greyBackground-card">
                <div className="greyBackground-title">Create Your Room</div>

                <div className="flex">
                    <div className="showcaseImage" style={{ marginLeft: 0 }}>
                        <img src={Room} alt="Create a Room in Scrum Poker" />
                    </div>
                    <div
                        className="greyBackground-description"
                        style={{ marginTop: 120 }}
                    >
                        Create a Room - If you Set up as an Admin it means you
                        can't participate directly in the estimating but if
                        using the app through Jira Marketplace you can pick the
                        tickets that are being estimated in the backlog and
                        directly add the estimates
                    </div>
                </div>

                <div className="greyBackground-title" style={{ marginTop: 30 }}>
                    Invite Your Team Members
                </div>

                <div className="flex" style={{ paddingBottom: 80 }}>
                    <div
                        className="greyBackground-description"
                        style={{ marginTop: 120 }}
                    >
                        Share the Link to your Scrum Poker Room and Your Team
                        can Join and Leave at their ease. Copy and Share the
                        link by hitting the button in the top right.
                    </div>

                    <div className="showcaseImage" style={{ marginRight: 0 }}>
                        <img src={Room2} alt="Votes counted in Scrum Poker" />
                    </div>
                </div>
                <div className="greyBackground-title" style={{ marginTop: 30 }}>
                    Estimate As a Team
                </div>

                <div className="flex">
                    <div
                        className="showcaseImage"
                        style={{ marginLeft: 0, marginBottom: 20 }}
                    >
                        <img src={Room3} alt="Estimate as a Team" />
                    </div>
                    <div
                        className="greyBackground-description"
                        style={{ marginTop: 120 }}
                    >
                        Estimating as a team is then easy its just the case of
                        everyone adding their estimates and then revealing the
                        total
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Documentation
