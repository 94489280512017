import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp19 } from '@fortawesome/free-solid-svg-icons'

const logo = <FontAwesomeIcon icon={faArrowUp19} />

const Footer = () => {
    return (
        <div className="Footer">
            <div className="AppHeader-logo ml-auto mr-auto">
                <a
                    href="/"
                    className="mr-3 flex-start w-4 overflow-hidden md:w-auto font-bold"
                >
                    ScrumPoker {logo}
                </a>
            </div>
        </div>
    )
}

export default Footer
