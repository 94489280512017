import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'

const Tooltip = ({ label, message }) => {
    return (
        <div className="Tooltip inline">
            <FontAwesomeIcon icon={faCircleInfo} />
            <div className="Tooltip-message">{message}</div>
        </div>
    )
}

export default Tooltip
