import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import 'flowbite'
import store from './app/store'
import App from './App'
import reportWebVitals from './reportWebVitals'
import WebSocketProvider from '../src/socket/WebSocket'
import './index.css'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
    <Provider store={store}>
        <WebSocketProvider>
            <App />
        </WebSocketProvider>
    </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
