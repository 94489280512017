export const SEND_MESSAGE_REQUEST = "SEND_MESSAGE_REQUEST"
export const UPDATE_PLAYER_NUMBER = "UPDATE_PLAYER_NUMBER"
export const REVEAL_CARDS = "REVEAL_CARDS"
export const PLAYER_JOINED = 'PLAYER_JOINED';
export const RETRIEVE_PLAYER_LIST = 'RETRIEVE_PLAYER_LIST';
export const SEND_PLAYER_DETAILS = 'SEND_PLAYER_DETAILS';

export function updatePlayerNumber(update){
    return {
        type: UPDATE_PLAYER_NUMBER,
        update
    }

}


export function revealCards(reveal) {
    return {
        type: REVEAL_CARDS,
        reveal
    }
}

export function playerJoinedFunc() {
    return {
        type: PLAYER_JOINED
    }
}


export function sendPlayerDetailsFunc(player) {
    return {
        type: SEND_PLAYER_DETAILS,
        player
    }
}

export function retrievePlayerListFunc(players) {
    return {
        type: RETRIEVE_PLAYER_LIST,
        players
    }
}