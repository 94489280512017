// These are our action types
export const CHANGE_CARD_NUMBER = 'CHANGE_CARD_NUMBER'

export function changeCardNumber(payload) {
    return {
        type: CHANGE_CARD_NUMBER,
        roomId: payload.roomId,
        payload,
    }
}
