import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import '../src/output.css'
import ThemeWrapper from './components/theme/ThemeWrapper'
import Create from '../src/components/create/Create'
import Home from '../src/components/home/Home'
import Dashboard from './components/dashboard/Dashboard'
import Privacy from './components/privacy/Privacy'
import Documentation from './components/documentation/Documentation'

function App() {
    return (
        <Router>
            <div className="App">
                <ThemeWrapper>
                    <Switch>
                        <Route exact path="/">
                            <Home />
                        </Route>
                        <Route path="/game">
                            <Create />
                        </Route>
                        <Route path="/profile">
                            <Dashboard />
                        </Route>
                        <Route path="/privacy">
                            <Privacy />
                        </Route>
                        <Route path="/guide-to-use">
                            <Documentation />
                        </Route>
                    </Switch>
                </ThemeWrapper>
            </div>
        </Router>
    )
}

export default App
