import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faMoon, faSun } from '@fortawesome/free-solid-svg-icons'
import logoPoker from '../home/images/logoTransparent.png'
import { useSelector } from 'react-redux'

const Header = ({
    // loggedInUser,
    isDarkMode,
    setIsDarkMode,
    setIsSignUpModalOpen,
    setIsLoginModalOpen,
    setLoggedInUser,
}) => {
    const user = useSelector((state) => state.user)

    let userProfilePhoto = ''
    if (user) {
        if (user?.avatarUrls) {
            userProfilePhoto = user?.avatarUrls?.['32x32']
        }
    }
    // const auth = getAuth(app)
    /*     const signOutAction = () => {
        signOut(auth)
            .then(() => {
                // Sign-out successful.
                dispatch(loggedInUserAction(null))
            })
            .catch((error) => {
                // An error happened.
            })
    } */
    return (
        <>
            <div
                className={`AppHeader sticky top-0 z-40 w-full backdrop-blur flex-none transition-colors duration-500 lg:z-50 lg:border-b lg:border-slate-900/10 bg-white supports-backdrop-blur:bg-white/95`}
            >
                <div className="max-w-8xl mx-auto">
                    <div className="py-4 border-b border-slate-900/10 lg:px-8 lg:border-0 dark:border-slate-300/10 mx-4 lg:mx-0">
                        <div className="relative flex items-center justify-between">
                            <div
                                className="AppHeader-logo"
                                style={{ height: 50 }}
                            >
                                <a
                                    href="/"
                                    className="mr-3 flex-start w-4 overflow-hidden md:w-auto font-bold"
                                >
                                    <img
                                        style={{ width: 100 }}
                                        src={logoPoker}
                                        alt="Scrum Poker Logo"
                                    />
                                </a>
                            </div>
                            <nav className="AppHeader-nav">
                                <ul className="flex">
                                    {/*                                     {!loggedInUser ? (
                                        <>
                                            <li
                                                onClick={() =>
                                                    setIsSignUpModalOpen(true)
                                                }
                                            >
                                                Sign Up
                                            </li>
                                            <li
                                                onClick={() =>
                                                    setIsLoginModalOpen(true)
                                                }
                                            >
                                                Log In
                                            </li>
                                            <li>
                                                <a href="/game">
                                                    <button
                                                        type="button"
                                                        className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                                    >
                                                        Create A Game
                                                    </button>
                                                </a>
                                            </li>
                                        </>
                                    ) : ( */}
                                    <>
                                        {/*                                             <li>
                                                <a href="/upgrade">Upgrade</a>
                                            </li> */}
                                        {/*                                         <li onClick={() => signOutAction()}>
                                            Sign Out
                                        </li> */}
                                        <li className="flex">
                                            <div className="profileCircle">
                                                {userProfilePhoto ? (
                                                    <img
                                                        alt="User Profile"
                                                        src={userProfilePhoto}
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={faUser}
                                                    />
                                                )}
                                            </div>
                                        </li>

                                        <li>
                                            {
                                                // eslint-disable-next-line no-undef
                                                AP?.request && (
                                                    <a href="/game">
                                                        <button
                                                            type="button"
                                                            className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                                        >
                                                            Create A Game
                                                        </button>
                                                    </a>
                                                )
                                            }
                                        </li>

                                        <li>
                                <label
                                    htmlFor="default-toggle"
                                    className="inline-flex relative items-center cursor-pointer"
                                >
                                    <input
                                        onChange={() => setIsDarkMode(!isDarkMode)}
                                        checked={isDarkMode}
                                        type="checkbox"
                                        value=""
                                        id="default-toggle"
                                        className="sr-only peer"
                                    />
                                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                    <div className="ml-2">
                                        {isDarkMode ? <FontAwesomeIcon icon={faMoon} /> : <FontAwesomeIcon icon={faSun} />}
                                    </div>
                                    <span className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                        {isDarkMode ? 'Dark Mode' : 'Light Mode'}
                                    </span>
                                </label>
                            </li>
                                    </>
                                    {/* )} */}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header
